
import Api from "@/services/Api";
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";
import Title from "@/components/Title.vue";
import Pagination from "@/components/Pagination.vue";
import Button from "@/components/Button.vue";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import { ElConfigProvider } from "element-plus";
// import ModalCreateEditBandeira from "./components/ModalCreateEditBandeira.vue";
import useEmitter from "@/composables/Emmiter";
import moment from 'moment';
import 'moment/locale/pt-br';
import useAlert from "@/composables/Alert";

interface LojasData {
    [codConcessionaria: string]: Loja[] | null;
}

interface Loja {
    store_name: string | null;
    cnpj: string | null;
    quantidade: string | null;
    manual: number | null;
    placaValor: number | null | string;
    manualValor: number | null | string;
    id: number | null;
}

interface Filtro {
    tipo: string | null;
    cnpj: string | null;
    limite: number | string | null;
    dataInicio: any;
}

export default defineComponent({
  name: "UsoApp",
  components: {Title, Button, Pagination },
  props: [],
  setup() {
    const router = useRouter();
    const route = useRoute();
    const emitter = useEmitter();
    const { showTimeAlert } = useAlert();
    const show: Ref<string | null> = ref('todas');
    const filtro: Ref<Filtro> = ref({tipo: null, cnpj: null, limite: null, dataInicio: getFirstDayOfPreviousMonth()})
    const loading = ref(false)
    const loadingTicket = ref(false)
    const spanWarning = ref(false)
    const lojas:Ref<Loja[] | null> = ref(null)
    const lojasPaginate:Ref<Loja[] | null> = ref(null)
    const total:Ref<any> = ref(150)
    const grupoOrConsultor:Ref<string | null> = ref(null)

    async function getLojasUsoApp(param:Filtro){
      if(!filtro.value.limite){
        spanWarning.value = true;
        return;
      }
      spanWarning.value = false;
      try {
        loading.value = true
        lojas.value = null
        const res = await Api.post('getLojasUsoApp', {...param});
        if(res.data !== 0 && res.data.length){
          lojas.value = res.data;
          firstPage();
        } 
        loading.value = false
      } catch (error) {
        console.log('ola', error)
      }   
    }
    
    async function createTickets(){
      try {
        loadingTicket.value = true
        const {data} = await Api.post('createTicketModelIndicar', {lojas: lojas.value, idProject: 6, idCategory: 9})
        if(data) return showTimeAlert(`Foram criados ${data} Tickets com sucesso!`, "success")
        return showTimeAlert(`Houve um erro ao processar sua solicitação`, "error")
      } catch (error) {
        console.log('error', error)
      } finally {
        loadingTicket.value = false
      }
    }

    function getFirstDayOfPreviousMonth() {
        const now = new Date();
        const firstDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        return firstDayOfPreviousMonth;
    }

    const firstPage = () => {
      lojasPaginate.value = lojas.value?.slice(0,9) as any;
    }

    function paginateFront(pageParam) {
      lojasPaginate.value = pageParam;
    }

    function formatarDataParaBanco(data: any) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss');
    }

    // onMounted(()=> getLojasUsoApp(null))
    
    return {lojas, route, lojasPaginate, show, paginateFront, total, loadingTicket, getLojasUsoApp, grupoOrConsultor, loading, ElConfigProvider, ptBr, filtro, spanWarning, createTickets };
    
  },
});
